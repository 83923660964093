export default {
  result: {
    CN: "21042418400445183115f47e62654f5ae245eb5a629d0b3c62dbcb5b66120b10",
    US: "caf74f64dac65174aa11d15c8af74e4cf7ba79fe9462784ce40e784397bbde4f",
    JP: "21362600402cd50231adee0062bd1608c2bd2908423d7208423d3738464b2110",
    KR: "faf23f7e22220802326e337e5a5208400292007e3e221424022032ff023e1c00",
  },
  stage: {
    A: "3c007e007e007e00ff00ff01ff81e781e783c3c3ffc7ffe7ffe781ef81ff00f",
    B: "3fe03ff83ff83cf83c3c3c783ff83ff03ff83ffc3c3c3c3c3c7c3ffc3ff83ff0",
    C: "7f00ff83ffc3ffe7c3c7800f800f000f000f80078107c3f3ffe3ffc0ff807e0",
    D: "ffe0fff8fffcfffcf03ef01ef01ff01ff01ff01ff01ef03efffefffcfff8ffe0",
    E: "3ffc3ffc3ffc3ffc3c003c003ff03ff03ff03ff03c003c003ffc3ffc3ffc3ffc",
    F: "3ffc3ffc3ffc3ffc3c003c003c003ff03ff03ff03c003c003c003c003c003c00",
    G: "7e00ff83ffc3ffe7c1c7800f800f8fff8fff8ff780f7e1f3fff3fff0fff07ef",
    H: "781e781e781e781e781e781e7ffe7ffe7ffe7ffe781e781e781e781e781e781e",
    I: "3c003c003c003c003c003c003c003c003c003c003c003c003c003c003c003c0",
    J: "1e001e001e001e001e001e001e001e001e001e003e383c7ffc3ff81ff80fe0",
    K: "f83ef87cf8f8f9f0fbe0ffc0ff80ff80ffc0ffc0fbe0f9f0f8f8f87cf87cf83e",
    L: "3c003c003c003c003c003c003c003c003c003c003c003c003ffc3ffc3ffc3ffc",
    M: "f00ff81ff83ffc3ffe7ffe7ffffffffff7eff7eff3cff3cff00ff00ff00ff00f",
    N: "781e7c1e7c1e7e1e7f1e7f9e7fde7fde7bfe7bfe79fe78fe787e787e783e781e",
    O: "7e01ff83ffc7ffe7c3ef81ff00ff00ff00ff00ff81f7c3f7ffe3ffc1ff807f0",
    P: "3ff03ff83ffc3ffc3c3c3c3c3c3c3ffc3ffc3ff83ff03c003c003c003c003c00",
    Q: "7e01ff83ffc3e7c781e781e781e781e781e7c3e3ffc3ff80ff007e003c003c0",
    R: "7fe07ff87ff87ffc7c3c7c3c7c7c7ffc7ff87ff07ff07df07cf87c787c7c7c3e",
    S: "7e00ff81ffc3ffc3c3c3f001fc01ff007fc01fc0c3c3c3c3ffc1ffc0ff807f0",
    T: "7ffe7ffe7ffe7ffe03c003c003c003c003c003c003c003c003c003c003c003c0",
    U: "781e781e781e781e781e781e781e781e781e781e781e7c3e3ffc3ffc1ff80fe0",
    V: "f80ff81f781e7c1e3c3e3c3c3e7c1e781e780f780ff00ff007e007e007e003c0",
    W: "e007e18773ce73ce73ce77ee3fec3e7c3e7c1e781c381c3800000000",
    X: "7c3e7c3e3e7c3ff81ff80ff00fe007e007e00ff00ff01ff83efc3e7c7c3ef83f",
    Y: "f81f7c3e7c3c3e7c1ff81ff00ff007e003c003c003c003c003c003c003c003c0",
    Z: "7ffe7ffe7ffe7ffe00fc01f803f003e007c00fc01f803f007ffe7ffe7ffe7ffe",
    1: "f001f003f007f00ff00ff006f002f000f000f000f000f000f000f000f000f0",
    2: "7e00ff01ff83ffc3e7c043c007c00f801f803f007e00f803ffc3ffc3ffc3ffc",
    3: "7e00ff81ff83e7c3c7c007c03f803f003f8007c003c3c3c3ffc3ffc1ff807e0",
    4: "7800f801f801f803f807f80f781f783e783c787ffe7ffe7ffe007800780078",
    5: "ff80ff81ff81ff81e001fc01ff01ff81efc043c003c1c3c3efc1ff81ff007e0",
    6: "1f003e003c007800f801fe01ff83ffc3e7c3c3c3c3c3c3c3ffc1ffc0ff807e0",
    7: "3ffc3ffc3ffc3ffc007800f801f001e003e003c007c007800f801f001e003e00",
    8: "7e00ff01ff81c781c381e781ff00fe01ff83ffc3c3c3c3c3e7c3ffc1ff807e0",
    9: "7e01ff83ff83ffc3c3c383c3c3c3e7c3ffc1ff80ff801f003e007c007800f00",
    0: "7e01ff03ff83ffc7c3e783e781e781e781e781e783e7c3e3ffc3ffc1ff007e0",
    "-": "ffffffffffffffffffffffff00000000000000000000",
  },
  stage_new: {
    A: "3c003c003c007e007e00e700e701e381c381c3c3ffc3ffc700e700e700fe007",
    B: "3fe03ff038783838383838383ff03ff03ff8383c381c381c381c387c3ff83ff0",
    C: "7f01ff83ffc781e700c7000e000e000e000e0007000700f381e3ffc1ff807e0",
    D: "ffc0fff0e0fce03ce00ee00ee00fe007e007e00fe00ee00ee03cfffcfff0ffc0",
    E: "3ffc3ffc3ffc3800380038003ff03ff03ff038003800380038003ffc3ffc3ffc",
    F: "3ffc3ffc3ffc38003800380038003ff03ff03800380038003800380038003800",
    G: "7e01ff83ffc381e700c7000e000e07fe07fe0077007700f7c1f3fff1fff07e7",
    H: "700e700e700e700e700e700e7ffe7ffe7ffe700e700e700e700e700e700e700e",
    I: "380038003800380038003800380038003800380038003800380038003800380",
    J: "e000e000e000e000e000e000e000e000e000e000c301c783c3e7c1ff807e0",
    K: "703c707870f071e073c073807f007f807f8079c071e070e070707078703c701c",
    L: "38003800380038003800380038003800380038003800380038003ff83ff83ff8",
    M: "e007f00ff00ff81ffc3ffc3fee77ef77e7e7e3c7e3c7e187e007e007e007e007",
    N: "700e780e7c0e7c0e7e0e7f0e778e738e73ce71ee70fe707e707e703e701e700e",
    O: "7e01ff83ffc781e700ee007e007e007e007e007e00f700e781e3ffc1ff807e0",
    P: "3fe03ff83ff8383c381c381c383c38383ff83ff03f8038003800380038003800",
    Q: "7e01ff83c3c381c700e700e600e600e700e700e381c3e781ff007e001800180",
    R: "3fe03ff03ff8383838383818383838783ff83ff038e038f0387038783838381c",
    S: "7e01ff01c78383c38183c001f800fe003f8007c001c381c381c1e381ff807e0",
    T: "7ffe7ffe7ffe0380038003800380038003800380038003800380038003800380",
    U: "700e700e700e700e700e700e700e700e700e700e700e781c381c3e7c1ff807e0",
    V: "e007700e700e700e381c381c3c381c381c780e700e7007e007e007c003c003c0",
    W: "c003e187618663c673ce33cc366c366c1e781c381c380c3000000000",
    X: "781e383c3c381e780ef00fe007e003c003c007e00ff01e701c383c3c781e700e",
    Y: "700e781e383c1c380e700ff007e003c003800380038003800380038003800380",
    Z: "7ffe7ffe001e003c007800f001e003c003c007800f001e003c007bfe7ffe7ffe",
    1: "7000f001f003f0077006700070007000700070007000700070007000700070",
    2: "7e00ff01e783c3c181c001c003c0038007801f003c007800f001dfc3ffc3ffc",
    3: "7e00ff01e781c38181c0038007803e003f80038001c381c383c1e780ff007e0",
    4: "30007000f001f003b007b00f300e301c3038307ffe7ffe7ffe003000300030",
    5: "ff80ff80c000c001c001d801ff01e781c38001c001c381c383c1e780ff007e0",
    6: "1e001c0038007000f001fc01ff03e78383c381c381c381c3c3c1e781ff807e0",
    7: "3ffc3ffc003800380070007000e001e001c003800380070007000e000e001c00",
    8: "7e00ff01c38181818181c380ff007e01ff81c38381c381c381c1e780ff007e0",
    9: "7e01ff81e78381c381c381c381c3c3c1e7c0ff807f000e001e003c003800700",
    0: "7e01ff83ffc381c781e700e700e700e700e700e700e780e381c3ffc1ff807e0",
    "-": "ffffffffffffffff000000000000000000000000",
  },
  dropType: {
    CN: {
      NORMAL_DROP:
        "564e564ffeeffee3fee7fe47fe477cf77cf71067fe6ffe6dd2fdd6ddde97d697",
      SPECIAL_DROP:
        "4404c4fcdefcfe4fe47fff74df74c2ff7fffffeeffaeda2e4a5f4a5f46d546c4",
      EXTRA_DROP:
        "40646f64ff74f474fe74fed6fed6eef76ef7fe35be35fe24be64bee4fbc4fbc4",
      FURNITURE:
        "100010de7ede7e401000ffffffff24cc6e5efe5a7e56fe7ffe51fee010ff10bf",
    },
    US: {
      NORMAL_DROP:
        "c0001800a0000400bb27869bd02706b2b02306b1b3671a9b0000001003000010",
      SPECIAL_DROP:
        "e0089800a000800096ee83db76c88202364c8201b6ce9adb1000000010000000",
      EXTRA_DROP:
        "e100e0008100800095549fad894c9a2e8d549a29f554ebad0000002000000020",
      FURNITURE:
        "80201c0080201400956895559533155595331555f56b1d550000000400020004",
    },
    JP: {
      NORMAL_DROP:
        "3e2abe2a4a7f4c7f1e41027fde22de3e423e5e08427f427f4249604bff4a9f08",
      SPECIAL_DROP:
        "800080ff80ff8003800380128216821e86148c108c109830b030f060c0e00040",
      EXTRA_DROP:
        "a718a71ca22432257601f791bfd13a503250345424143424162c532873000",
      FURNITURE:
        "abb5abb5abb5a891a8d1a8c1acc9acc9acc9acc9a889a88988a8cba9dba9d3a9",
    },
    KR: {
      NORMAL_DROP:
        "20272daf2da9afa9afadadbf2db72fa00fa7efa7e027e627e6060607e7e7e7e",
      SPECIAL_DROP:
        "7e187e1840187e1c403c7e777e670000ffffffff00187e187e18061806180618",
      EXTRA_DROP:
        "100610067e7e7e7e181e38167c17e61742360026ff6610c610c6100610061006",
      FURNITURE:
        "201f201f2791209fe09fe198279fa79fa19023100317e610261024102010201",
    },
  },
  item: {
    CN: {
      1: "3800f800f80018001800180018001800180018001800180018001801ff01ff0",
      2: "7c00fe0187000300030003000300070006000e001c0038007000e001ff81ff8",
      3: "7c00ff00c3000300030003000f003c003f0003000180018001818381ff007e0",
      4: "6000e001e001e00360076006600c600c6018603ffc3ffc0060006000600060",
      5: "7f00ff00c000c000c000c000fe00c700038001800180018003818381ff007c0",
      6: "1e007f80e100c000c00180019e01ff01c301818181818180c180e3007f003e0",
      7: "1ff81ff800300070006000c000c001c001800180018003800380038003000300",
      8: "3c007f00c300c300c100c30073003e007e00c701838181818181c380ff007e0",
      9: "3c00fe00c3018301818181818180c380ff807d800180030003008701fe007c0",
      0: "3c007e00c300c301c3818181818181818181818181818380c300c3007e003c0",
    },
    US: {
      1: "1e003e007e007e004e000e000e000e000e000e000e000e000e000e000e000e0",
      2: "7e00ff00e300c380c380038007001f007e00f801c001c001c001c001ff81ff8",
      3: "7e00ff01c301c380038003000f003e000f00038003800381c381c380ff00fe0",
      4: "e001e001e003e0036007600e600e601c6018601ff81ff80060006000600060",
      5: "1ff01ff01c001c001c001fe01ff01e701c380038003800381c381c300ff00fe0",
      6: "7e00ff00e700c301c001dc01ff01ff01c301c381c381c380c300e700ff007e0",
      7: "ff00ff000700070007000e000e000e000c001c0018003800380030007000700",
      8: "7e00ff01c301c381c380c300ff007e00e701c381c381c381c381c380ff007e0",
      9: "7e00ff01c701c301c3018381c381c380ff80ff8003000300c300c700ff007e0",
      0: "7e00ff00e700c301c381c381c381c381c381c381c381c380c300e700ff007e0",
    },
    JP: {
      1: "e001e007e007e006e000e000e000e000e000e000e000e000e000e000e000e0",
      2: "7e00ff01e781c3818180038003800f003e007c00f000e001c0018001ff81ff8",
      3: "7e00ff01e381c380018003801f003e003f80038001c081c381c1c381ff807f0",
      4: "7000f001f003f003f007700e701c701c7038707ffc7ffc7ffc007000700070",
      5: "1ff81ff81c00180018001bc01ff01e783838001c001c081c38381c781ff007e0",
      6: "7e00ff81e381c18180039c03ff03ff83c38381c381c381c1c181e380ff007e0",
      7: "3ffc3ffc00380070007000e001c001c003800380038007000700070007000700",
      8: "7e00ff01c38181818181c381ff80ff01ff83c1c381c381c381c1c381ff807e0",
      9: "7e00ff01c783838381c381c381c1c3c1ffc0ffc039c001818381c781ff007e0",
      0: "7e00ff01e781c381818381c381c381c381c381c381c18181c381e780ff007e0",
    },
    KR: {
      1: "3c01fc01fc01fc003c003c003c003c003c003c003c003c003c01ff81ff81ff8",
      2: "7c01ff01ff01c78007800780078007000f001e003e007c00f801ff81ff81ff8",
      3: "7e01ff01ff80c780078007801f003e003f000f80038003818781ff81ff00fe0",
      4: "1f001f003f003f007f00f700e701e701c703ffc3ffc3ffc0070007000700070",
      5: "ff80ff80ff80e000e000fc00ff00ff80c7800380038003818783ff81ff00fe0",
      6: "3e007f80ff80e101e001c001ff01ff81f781c381c381c381e380ff807f003e0",
      7: "3ff83ff83ff8007000f000e001e001c003c003c0038003800380078007800780",
      8: "3e00ff01ff01e381c381e380f7007e00ff01ef81c781c381c381e781ff007e0",
      9: "7c00fe01ff01c781c381c381c381e781ff807b80038007808781ff01fe00fc0",
      0: "7e00ff00ff01e781c381c383c383c383c383c381c381c381e780ff00ff007e0",
    },
  },
};
