<template>
  <div
    class="d-flex flex-column align-start py-12 px-6"
  >
    <v-icon
      :size="64"
      class="mb-2 degraded-opacity"
    >
      mdi-clock-alert
    </v-icon>
    <h1 class="mb-4">
      是时候升级你的浏览器了
    </h1>

    <v-alert
      outlined
      type="warning"
      icon="mdi-alert-circle"
      dense
    >
      在升级您的浏览器之前，您将无法使用截图识别汇报功能。
    </v-alert>

    <p class="subtitle-1">
      抱歉，但您的浏览器并不支持截图识别汇报所使用的 WebAssembly 技术。<br>
      您可从下方选择一些我们推荐您使用的浏览器、跳转官网自行更新。
    </p>

    <div class="mb-6">
      <v-card
        v-for="browser in browsers"
        :key="browser.id"
        class="d-inline-flex align-center mr-2"
        outlined
        :href="browser.url"
        target="_blank"
      >
        <v-card-text>
          <svg
            style="width:24px;height:24px"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              :d="browser.icon.svg"
            />
          </svg>
          <div class="d-flex flex-column align-start">
            <span
              class="title text--text"
              v-text="browser.title"
            />
            <!--            <span-->
            <!--              class="subtitle-2"-->
            <!--              v-text="browser.subtitle"-->
            <!--            />-->
            <span
              class="caption"
              v-text="browser.url.replace('https://', '')"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>

    <span class="caption">
      通常此问题是由于您当前所使用的浏览器版本过旧而所导致，升级或更换浏览器即可解决。但若您使用的是形如 QQ 浏览器、搜狗浏览器等国产厂商所开发的浏览器，即便版本已是最新，由于这类浏览器通常内核更新缓慢、不遵守 Web 标准、导致了预期外的行为发生，也可能出现此警告。如果您使用了上述浏览器，您将亦需更换您的浏览器以使用此功能。
    </span>

    <span
      class="monospace mt-2"
      style="font-size: 12px"
    >
      reason:{{ reason }} | ver:{{ env.version }}+{{ env.commit }} | env:{{ env.userAgent }}
    </span>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: "BrowserDeprecated",
  props: {
    reason: {
      type: String,
      default: () => ""
    },
  },
  data() {
    return {
      browsers: [
        {
          id: 'firefox',
          icon: {
            svg: 'M9.27 7.94C9.27 7.94 9.27 7.94 9.27 7.94M6.85 6.74C6.86 6.74 6.86 6.74 6.85 6.74M21.28 8.6C20.85 7.55 19.96 6.42 19.27 6.06C19.83 7.17 20.16 8.28 20.29 9.1L20.29 9.12C19.16 6.3 17.24 5.16 15.67 2.68C15.59 2.56 15.5 2.43 15.43 2.3C15.39 2.23 15.36 2.16 15.32 2.09C15.26 1.96 15.2 1.83 15.17 1.69C15.17 1.68 15.16 1.67 15.15 1.67H15.13L15.12 1.67L15.12 1.67L15.12 1.67C12.9 2.97 11.97 5.26 11.74 6.71C11.05 6.75 10.37 6.92 9.75 7.22C9.63 7.27 9.58 7.41 9.62 7.53C9.67 7.67 9.83 7.74 9.96 7.68C10.5 7.42 11.1 7.27 11.7 7.23L11.75 7.23C11.83 7.22 11.92 7.22 12 7.22C12.5 7.21 12.97 7.28 13.44 7.42L13.5 7.44C13.6 7.46 13.67 7.5 13.75 7.5C13.8 7.54 13.86 7.56 13.91 7.58L14.05 7.64C14.12 7.67 14.19 7.7 14.25 7.73C14.28 7.75 14.31 7.76 14.34 7.78C14.41 7.82 14.5 7.85 14.54 7.89C14.58 7.91 14.62 7.94 14.66 7.96C15.39 8.41 16 9.03 16.41 9.77C15.88 9.4 14.92 9.03 14 9.19C17.6 11 16.63 17.19 11.64 16.95C11.2 16.94 10.76 16.85 10.34 16.7C10.24 16.67 10.14 16.63 10.05 16.58C10 16.56 9.93 16.53 9.88 16.5C8.65 15.87 7.64 14.68 7.5 13.23C7.5 13.23 8 11.5 10.83 11.5C11.14 11.5 12 10.64 12.03 10.4C12.03 10.31 10.29 9.62 9.61 8.95C9.24 8.59 9.07 8.42 8.92 8.29C8.84 8.22 8.75 8.16 8.66 8.1C8.43 7.3 8.42 6.45 8.63 5.65C7.6 6.12 6.8 6.86 6.22 7.5H6.22C5.82 7 5.85 5.35 5.87 5C5.86 5 5.57 5.16 5.54 5.18C5.19 5.43 4.86 5.71 4.56 6C4.21 6.37 3.9 6.74 3.62 7.14C3 8.05 2.5 9.09 2.28 10.18C2.28 10.19 2.18 10.59 2.11 11.1L2.08 11.33C2.06 11.5 2.04 11.65 2 11.91L2 11.94L2 12.27L2 12.32C2 17.85 6.5 22.33 12 22.33C16.97 22.33 21.08 18.74 21.88 14C21.9 13.89 21.91 13.76 21.93 13.63C22.13 11.91 21.91 10.11 21.28 8.6Z'
          },
          title: 'Mozilla Firefox',
          url: 'https://mozilla.org/firefox/new/'
        },
        {
          id: 'edge',
          icon: {
            svg: 'M10.86 15.37C10.17 14.6 9.7 13.68 9.55 12.65C9.25 13.11 9 13.61 8.82 14.15C7.9 16.9 9.5 20.33 12.22 21.33C14.56 22.11 17.19 20.72 18.92 19.2C19.18 18.85 21.23 17.04 20.21 16.84C17.19 18.39 13.19 17.95 10.86 15.37M11.46 9.56C12.5 9.55 11.5 9.13 11.07 8.81C10.03 8.24 8.81 7.96 7.63 7.96C3.78 8 .995 10.41 2.3 14.4C3.24 18.28 6.61 21.4 10.59 21.9C8.54 20.61 7.3 18.19 7.3 15.78C7.38 13.25 8.94 10.28 11.46 9.56M2.78 8.24C5.82 6 10.66 6.18 13.28 9C14.3 10.11 15 12 14.07 13.37C12.33 15.25 17.15 15.5 18.18 15.22C21.92 14.5 22.91 10.15 21.13 7.15C19.43 3.75 15.66 1.97 11.96 2C7.9 1.93 4.25 4.5 2.78 8.24Z'
          },
          title: 'Microsoft Edge',
          url: 'https://microsoft.com/edge'
        },
        {
          id: 'chrome',
          icon: {
            svg: 'M12,20L15.46,14H15.45C15.79,13.4 16,12.73 16,12C16,10.8 15.46,9.73 14.62,9H19.41C19.79,9.93 20,10.94 20,12A8,8 0 0,1 12,20M4,12C4,10.54 4.39,9.18 5.07,8L8.54,14H8.55C9.24,15.19 10.5,16 12,16C12.45,16 12.88,15.91 13.29,15.77L10.89,19.91C7,19.37 4,16.04 4,12M15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12M12,4C14.96,4 17.54,5.61 18.92,8H12C10.06,8 8.45,9.38 8.08,11.21L5.7,7.08C7.16,5.21 9.44,4 12,4M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
          },
          title: 'Google Chrome',
          url: 'https://google.cn/chrome'
        }
      ]
    }
  },
  computed: {
    env() {
      return {
        version: config.version,
        commit: GIT_COMMIT.trim() || 'unknown',
        userAgent: navigator.userAgent || 'unknown'
      };
    }
  },
}
</script>

<style scoped>

</style>